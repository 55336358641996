import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, MenuUnfoldOutlined, CloseOutlined, CompressOutlined, UndoOutlined, ExpandOutlined } from '@ant-design/icons';
import Select from 'react-select';
import i18n from '../../i18next'
import '../Header/style.css';
import { AutoRotateContext } from '../pauseState/AutoRotateContext';
import { faL } from '@fortawesome/free-solid-svg-icons';

const options = [
  {
    value: 'vi',
    label: (
      <div style={{ display: 'flex', alignItems: 'center', width: 123, height: 24 , }}>
        Tiếng Việt
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/225px-Flag_of_Vietnam.svg.png"
          alt="Vietnam"
          style={{ width: 40, height: 24, marginLeft: 10 }}
        />
      </div>
    ),
  },
  {
    value: 'en',
    label: (
      <div style={{ display: 'flex', alignItems: 'center', width: 123, height: 24 }}>
        Tiếng Anh
        <img
          src="https://th.bing.com/th/id/OIP.bsAUet3tu20BMiLW93wTqQHaE3?rs=1&pid=ImgDetMain"
          alt="English"
          style={{ width: 40, height: 24, marginLeft: 10 }}
        />
      </div>
    ),
  },
];

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function MenuItem() {
  const { t, i18n } = useTranslation();
  const containerRef = useRef(null);
  const [showMenu, setShowMenu] = useState(true)

  const ClickMenu = () => {
    setShowMenu(!showMenu)
  }

  const [visiable, setvisiable] = useState(false);

  const handleClick = () => {
    setvisiable(!visiable);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setvisiable(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setvisiable(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isRotated, setIsRotated] = useState(false);

  const handleMouseEnter = () => {
    setIsRotated(true);
  };

  const handleMouseLeave = () => {
    setIsRotated(false);
  };

  const links = ['*', '/library', '/a7', '/a6', '/a1', '/a2', '/a3', '*', '/a5', '/giao-duc-the-chat', '*', '*', '*', '*', '*', '*',]

  const handleItemClick = (index) => {
      window.location.href = links[index];
  };
  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <div className='menuLeft' onClick={ClickMenu} style={{ backgroundColor: 'rgba(243, 113, 37, 0.9)', marginRight: 5, borderRadius: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
        <MenuUnfoldOutlined style={{ fontSize: '16px', color: '#ffffff' }} />
      </div>
      {showMenu && (
        <div className={`menu ${showMenu ? 'slide-in' : 'slide-out'}`}>
          <div className="header-left" style={{ height: '50%', }}>
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="header-button"
              ref={containerRef}
              onClick={handleClick}
              style={{
                backgroundColor: 'rgba(243, 113, 37, 0.9)',
                display: 'flex',
                width: 300,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '999999',
              }}
            >
              <PlusOutlined style={{
                color: 'white', fontWeight: '100px',
                transition: 'transform 0.3s ease',
                transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
              }} />
              &nbsp;
              &nbsp;
              <p style={{ color: 'white', fontWeight: '100px' }}>
                {t('university')}
              </p>
            </div>
            <ul style={{
              width: 300,
              maxHeight: visiable ? '240px' : '0px',
              overflowY: visiable ? 'auto' : 'hidden',
              msOverflowStyle:'none',
              scrollbarWidth:'none',
            }}
              className={`items-container ${visiable ? 'show' : ''}`}>
              {t('schoolItems', { returnObjects: true }).map((item, index) => (
                <li onClick={() => handleItemClick(index)} key={index} className={`item ${visiable ? 'show' : ''}`}>
                  <a href={links[index]} target="_self" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div onClick={() => setShowMenu(false)} className='closeMenu' style={{ backgroundColor: 'red', marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
            <CloseOutlined style={{ fontSize: '16px', color: '#ffffff' }} />
          </div>
        </div>
      )}
    </div>
  );
}

function Header() {
  const containerRef = useRef(null);

  const { t, i18n } = useTranslation();


  const [selectedLanguage, setSelectedLanguage] = useState(options[0]); // Default to Vietnamese

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setvisiable(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLanguageSwitch = (selectedOption) => {
    setSelectedLanguage(selectedOption); // Update selected language
    console.log('Selected language:', selectedOption.value);
    i18n.changeLanguage(selectedOption.value);
  };

  const { autoRotate, setAutoRotate } = useContext(AutoRotateContext);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };
  
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, position: 'absolute', zIndex: 9999999, padding: 20, }}>
        <MenuItem />
        <div onClick={() => setAutoRotate(!autoRotate)} className='menuLeft' style={{ backgroundColor: 'rgba(243, 113, 37, 0.9)', marginRight: 5, borderRadius: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} title={autoRotate ? 'Dừng xoay' : 'Xoay tự động'}>
          <UndoOutlined style={{ fontSize: '16px', color: '#ffffff' }} />
        </div>
        <div className='menuLeft' style={{ backgroundColor: 'rgba(243, 113, 37, 0.9)', marginRight: 5, borderRadius: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} title={isFullScreen ? 'Thu màn hình' : 'Toàn màn hình'}>
          {isFullScreen ? <CompressOutlined onClick={toggleFullScreen} style={{ fontSize: '16px', color: '#ffffff' }} title='Thu màn hình'/> : <ExpandOutlined onClick={toggleFullScreen} style={{ fontSize: '16px', color: '#ffffff' }} title='Toàn màn hình'/>}
        </div>
        <div className='menuLeft' style={{ backgroundColor: 'rgba(243, 113, 37, 0.9)', marginRight: 5, borderRadius: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} title='Xem với kính thực tế ảo'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 22" fill="none" width="21" height="21" title='Xem với kính thực tế ảo'>
            <path fill="white" d="M27.4397 21.1074L23.2182 16.8859H18.9967L14.7752 21.1074H10.5537C9.43411 21.1074 8.36035 20.6627 7.56867 19.871C6.77699 19.0793 6.33223 18.0056 6.33223 16.8859V4.22149C6.33223 3.10188 6.77699 2.02813 7.56867 1.23644C8.36035 0.444762 9.43411 0 10.5537 0H31.6611C32.7808 0 33.8545 0.444762 34.6462 1.23644C35.4379 2.02813 35.8826 3.10188 35.8826 4.22149V16.8859C35.8826 18.0056 35.4379 19.0793 34.6462 19.871C33.8545 20.6627 32.7808 21.1074 31.6611 21.1074H27.4397ZM42.2149 14.7752C42.2149 15.335 41.9925 15.8719 41.5966 16.2677C41.2008 16.6636 40.6639 16.8859 40.1041 16.8859C39.5443 16.8859 39.0074 16.6636 38.6116 16.2677C38.2158 15.8719 37.9934 15.335 37.9934 14.7752V6.33223C37.9934 5.77242 38.2158 5.23555 38.6116 4.83971C39.0074 4.44387 39.5443 4.22149 40.1041 4.22149C40.6639 4.22149 41.2008 4.44387 41.5966 4.83971C41.9925 5.23555 42.2149 5.77242 42.2149 6.33223V14.7752ZM4.22149 14.7752C4.22149 15.335 3.9991 15.8719 3.60326 16.2677C3.20742 16.6636 2.67055 16.8859 2.11074 16.8859C1.55094 16.8859 1.01406 16.6636 0.618222 16.2677C0.222381 15.8719 0 15.335 0 14.7752V6.33223C0 5.77242 0.222381 5.23555 0.618222 4.83971C1.01406 4.44387 1.55094 4.22149 2.11074 4.22149C2.67055 4.22149 3.20742 4.44387 3.60326 4.83971C3.9991 5.23555 4.22149 5.77242 4.22149 6.33223V14.7752ZM17 8C17 9.65685 15.6569 11 14 11C12.3431 11 11 9.65685 11 8C11 6.34315 12.3431 5 14 5C15.6569 5 17 6.34315 17 8ZM29 11C30.6569 11 32 9.65685 32 8C32 6.34315 30.6569 5 29 5C27.3431 5 26 6.34315 26 8C26 9.65685 27.3431 11 29 11Z" clip-rule="evenodd" fill-rule="evenodd" />
          </svg>
        </div>
      </div>
      <div style={{ position: 'absolute', zIndex: 9999999, padding: 20, right: 10 }}>
        <Select
          value={selectedLanguage}
          size='small'
          options={options}
          onChange={handleLanguageSwitch}
          isSearchable={false}
          styles={{
            padding: 8,
            fontSize: 18,
            backgroundColor: '#fa9627',
            color: 'white',
            border: '1px solid #ffffff',
            width: 200,
            position: 'absolute',
            height: '30px',
            lineHeight: '30px ',
          }}
        />
      </div>
    </>
  );
}

export default Header;
