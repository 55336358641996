import * as THREE from 'three';
import { Suspense, useState, useContext, useRef, useEffect } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { Html, Preload, OrbitControls } from '@react-three/drei';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AutoRotateContext } from '../../Component/pauseState/AutoRotateContext';
import { LoadingScreen } from "../../Component/LoadingScreen/LoadingScreen"
import { store } from "./Store.js"
import '../A3/styles.css'
import LoadingArrow from '../LoadingArrow/LoadingArrow.js';

function Dome({ name, position, texture, onClick, arrows, rotation, equipment }) {
	const [clickedPoint, setClickedPoint] = useState(null);
	const [hovered, setHovered] = useState(null);
	const { autoRotate, setAutoRotate } = useContext(AutoRotateContext);
	const [selectedEquipment, setSelectedEquipment] = useState(null);
	const meshRef = useRef();
	const navigate = useNavigate();

	const handleArrowClick = (arrow) => {
		if (arrow.position[0] === 75 && arrow.position[1] === -10 && arrow.position[2] === 10) {
			navigate('/library');
		} else if (arrow.position[0] === -1.69 && arrow.position[1] === -13 && arrow.position[2] === -79.91) {
			navigate('/a6');
		} else if (arrow.position[0] === -75 && arrow.position[1] === -10 && arrow.position[2] === 10){
			navigate('/a1')
		}else {
			onClick(arrow.link);
		}
	};
	const handleEquipmentClick = (index) => {
		setSelectedEquipment(equipment[index]);
	};

	const onClickPoint = (event) => {
		// Normalize mouse coordinates (-1 to 1)
		const mouse = new THREE.Vector2();
		mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
		mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
		// Raycaster
		const raycaster = new THREE.Raycaster();
		raycaster.setFromCamera(mouse, event.camera);
		// Intersected objects
		const intersects = raycaster.intersectObject(meshRef.current);
		if (intersects.length > 0) {
			const point = intersects[0].point;
			// Set clicked point
			setClickedPoint(point);
			// Log clicked point coordinates
			console.log(`Toạ độ x,y,z: ${point.x.toFixed(2)},${point.y.toFixed(2)},${point.z.toFixed(2)}`);
		}
	};
	return (
		<group>
			<mesh ref={meshRef} scale={1} rotation={rotation} position={position}
				onClick={(event) => { onClickPoint(event); }}>
				<sphereGeometry args={[80, 80, 80]} />
				<meshBasicMaterial map={texture} side={THREE.BackSide} />
			</mesh>

			{arrows.map((arrow, index) => (
				<mesh key={index} position={arrow.position}>
					<Html position={arrow.position} style={{ transform: 'translate(-50%, -50%)' }}>
						<div
							onClick={() => { handleArrowClick(arrow) }}
							onMouseEnter={() => {
								setHovered(index);
								setAutoRotate(false);
							}}
							onMouseLeave={() => {
								setHovered(null);
								setAutoRotate(false);
							}}
							style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<img className='arrowIcon' src='https://vr360.com.vn/projects/dai-hoc-ngan-hang-tp-hcm/assets/Move/up.png' alt='' />
							{hovered === index && (
								<div
									style={{
										display: 'flex',
										position: 'absolute',
										justifyContent: 'center',
										alignItems: 'center',
										top: '-60px',
										width: '100px',
										height: '50px',
										backgroundColor: '#fa9627',
										borderRadius: '20%',
									}}
								>
									<div style={{ justifyContent: 'center' }}>
										{/* <img src={arrow.preview} alt='' style={{ width: '140px', height: '90px', borderRadius: '20%' }} /> */}
										<p style={{ textAlign: 'center', color: 'white' }}>{arrow.ad}</p>
									</div>
								</div>
							)}
						</div>
					</Html>
				</mesh>
			))}

			<mesh>
				{equipment && equipment.map((items, index) => (
					<mesh key={index} position={items.position}>
						<Html position={items.position} style={{ transform: 'translate(-50%, -50%)' }}>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								{/* <img onClick={() => handleEquipmentClick(index)} src='itemAbout.png' style={{ width: 20, height: 20, cursor: 'pointer' }} /> */}
								<svg onClick={() => handleEquipmentClick(index)} xmlns="http://www.w3.org/2000/svg" stroke-opacity="1" height="44" viewBox="0 0 44 44" width="44" stroke="#fa9627">
									<g fill="none" stroke-width="2" fill-rule="evenodd">
										<circle cx="22" r="1" cy="22">
											<animate keySplines="0.165, 0.84, 0.44, 1" attributeName="r" calcMode="spline" keyTimes="0; 1" begin="0s" repeatCount="indefinite" dur="1.8s" values="1; 20" />
											<animate keySplines="0.3, 0.61, 0.355, 1" attributeName="stroke-opacity" calcMode="spline" keyTimes="0; 1" begin="0s" repeatCount="indefinite" dur="1.8s" values="1; 0" />
										</circle>
										<circle cx="22" r="1" cy="22">
											<animate keySplines="0.165, 0.84, 0.44, 1" attributeName="r" calcMode="spline" keyTimes="0; 1" begin="-0.9s" repeatCount="indefinite" dur="1.8s" values="1; 20" />
											<animate keySplines="0.3, 0.61, 0.355, 1" attributeName="stroke-opacity" calcMode="spline" keyTimes="0; 1" begin="-0.9s" repeatCount="indefinite" dur="1.8s" values="1; 0" />
										</circle>
									</g>
								</svg>
							</div>
						</Html>
					</mesh>
				))}
				{selectedEquipment && (
					<mesh position={selectedEquipment.position}>
						<Html position={selectedEquipment.position} style={{ transform: 'translate(-50%, -50%)' }}>
							<div style={{ backgroundColor: '#fa9627', padding: '10px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', width: 200 }}>
								<p style={{ color: 'white' }}>{selectedEquipment.info}</p>
								<button style={{ borderRadius: '5px' }} onClick={() => setSelectedEquipment(null)}>Huỷ</button>
							</div>
						</Html>
					</mesh>
				)}
			</mesh>

		</group>
	);
}

function Portals() {
	const { autoRotate, setAutoRotate } = useContext(AutoRotateContext);
	const [which, set] = useState(0); // Set initial state to 1 to start with Toaa3.jpg
	const { link, ...props } = store[which];
	const [loading, setLoading] = useState(false);
	const { autoRotateSpeed } = useContext(AutoRotateContext);

	const handleChangeScene = (newLink) => {
		setLoading(true);
		setTimeout(() => {
			set(newLink);
			setLoading(false);
		}, 500); // Adjust delay as needed
	};
	const maps = useLoader(THREE.TextureLoader, store.map((entry) => entry.url)); // prettier-ignore
	return (
		<>
			{loading &&
				<Html>
					<LoadingArrow />
				</Html>
			}
			<Dome
				onClick={handleChangeScene}
				{...props}
				texture={maps[which]}
				arrows={store[which].arrows}
				equipment={store[which].equipment}
				setAutoRotate={setAutoRotate}
			/>
		</>
	);
}

function Technology() {
	const { autoRotate, setAutoRotate } = useContext(AutoRotateContext);
	const { autoRotateSpeed } = useContext(AutoRotateContext)

	const controlsRef = useRef();

	useEffect(() => {
		// Ensure controlsRef.current is not undefined
		if (controlsRef.current) {
			// You can log controlsRef.current to debug if needed
			console.log(controlsRef.current);
		}
	}, [controlsRef]);

	return (
		<Canvas frameloop="demand" camera={{ position: [-100, 0, -1000] }} style={{ cursor: 'url(https://marriott-f0bec.web.app/danphuong_hamo/lib/cursors/grab.cur), default' }}>
			<OrbitControls
				ref={controlsRef}
				minDistance={1} // Độ zoom nhỏ nhất 
				maxDistance={5} // Độ zoom lớn nhất
				enableZoom={false}
				enablePan={true}
				enableDamping={true}
				dampingFactor={0.25}
				autoRotate={autoRotate} // bật chế độ tự động xoay
				rotateSpeed={-0.5} // Hướng kéo chuột 
				autoRotateSpeed={autoRotateSpeed} // tốc độ xoay 
			/>
			<Suspense fallback={<LoadingScreen />}>
				<Preload all />
				<Portals
					controlsRef={controlsRef}
					setAutoRotate={setAutoRotate}
					autoRotateSpeed={autoRotateSpeed}
				/>
			</Suspense>
		</Canvas>
	);
}

export default Technology;
