export const BUILDING_IDS = {
    a6: "673fe87b9f23c6560cefb40f",
    a3: "673fec689f23c6560cefb41d",
    a2: "678086dd12c0d8db84c6d870",
    a1: "678086c312c0d8db84c6d86c",
    library:"673fe9ef9f23c6560cefb41a",
    a5:"6780884d12c0d8db84c6d878",
    a7: "6780882b12c0d8db84c6d874",
    "giao-duc-the-chat": "6780887112c0d8db84c6d87c",
    "":"676280eb2776f53c32814a68"
    // Thêm các tòa nhà khác tại đây
};

// Hàm helper để lấy ID dựa trên path
export const getBuildingIdFromPath = (path) => {
    // Loại bỏ dấu "/" từ path và chuyển thành lowercase
    const building = path.replace('/', '').toLowerCase();
    return BUILDING_IDS[building] || null;
}