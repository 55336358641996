import React from 'react';
import '../LoadingArrow/loadingStyles.css'; // Create a CSS file for loading styles

function LoadingArrow() {
  return (
    <div className="loading-container">
      {/* <div className="spinner"></div> */}
      <img style={{width:100 , height:100}} src='https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimsS0vQUT39JdISTxS4Ms0fnCfozbFUCrsUmgzIH6B8lrUsU8eXRA1qbMgqX5z8vfDhVjVCZ5qJTI-rVZAjPAwM2T97TsmLMH1QrwXdhzozZWYX93o4RNF5eKWSP0lOdFX5Rw2OCThrOc/s1600/loading+%25283%2529.gif'/>
    </div>
  );
}

export default LoadingArrow;
