import React, { createContext, useState } from 'react';

export const AutoRotateContext = createContext();

export const AutoRotateProvider = ({ children }) => {
    const [autoRotate, setAutoRotate] = useState(true);
    const autoRotateSpeed = 0.2;
    return (
        <AutoRotateContext.Provider value={{ autoRotate, setAutoRotate , autoRotateSpeed }}>
            {children}
        </AutoRotateContext.Provider>
    );
};

