import React, { useCallback, useState, useMemo, useEffect } from 'react';

import { Button, Divider, Flex, Radio } from 'antd'
import { CloseOutlined } from '@ant-design/icons';

function Contact() {
  const [size, setSize] = useState('large');
  return (
    <div style={{ position: 'absolute', top: 0, width: 1200 , backgroundColor:'white' }}>
      <Button type="primary" icon={<CloseOutlined />} size={size} style={{left: '97%' }} />
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15018.734198011669!2d105.7579011871582!3d19.768595100000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3136f87eb8b4c8c5%3A0x88d1e9c521aef3a9!2zxJBhzKNpIEhvzKNjIEjDtMyAbmcgxJDGsMyBYyAx!5e0!3m2!1sfr!2s!4v1715850514238!5m2!1sfr!2s"
          style={{ width: '100%', height: '800px', border: '0', }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe> */}
    </div>
  );
}

export default Contact;
