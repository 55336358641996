import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div>
           <div className="not-found">
                <img
                    src="https://www.pngitem.com/pimgs/m/561-5616833_image-not-found-png-not-found-404-png.png"
                    alt="not-found"
                    style={{width:'100%',height:'100%'}}
                />
                <Link to="/" className="link-home" style={{textDecoration:'none', position:'absolute' , justifyContent:'center' , alignContent:'center'}}>
                    Go Home
                </Link>
            </div>
        </div>
    )
}