import { useProgress, Html } from "@react-three/drei";
import { Space, Spin } from 'antd';
import "./LoadingScreen.css";

export const LoadingScreen = () => {
  const { progress } = useProgress();
  return (
    <Html center>
      <div className="loadingScreen">
        <div className="loadingScreen_board">
        {/* <div> {`Đang tải... ${Math.round(progress)}%`}</div> */}
          <Space direction="vertical" align="center">
            <Spin size="large"></Spin>
          </Space> 
        </div>
      </div>
    </Html>
  );
};
