import React, { useState, useEffect, useRef } from 'react';
import './style.css'
import { PlusSquareOutlined, MoonOutlined, SunOutlined, AudioOutlined , CaretDownOutlined} from '@ant-design/icons';
import { Button } from 'antd';

const ChatBot = () => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const textareaRef = useRef(null);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [messages]);

    const handleSendMessage = () => {
        if (message.trim() === '') return;

        setMessages([...messages, {
            text: message,
            type: 'user'
        }]);

        setMessage('');
    };

    const handleNewChat = () => {
        setMessages([]);
        setMessage('');
    };

    const handleSuggestionClick = (suggestionText) => {
        setMessages([...messages, {
            text: suggestionText,
            type: 'user'
        }]);
    };

    const handleWheel = (e) => {
        const element = e.currentTarget;
        const isScrollable = element.scrollHeight > element.clientHeight;

        if (isScrollable) {
            e.stopPropagation();
        }
    };

    const [dark, setDark] = useState(true);
    const handleConvertDark = () => {
        setDark(!dark)
    }
    const [showScrollButton, setShowScrollButton] = useState(false);

    const handleScroll = (e) => {
        const container = e.currentTarget;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        
        // Hiện nút khi scroll lên trên và ẩn khi ở dưới cùng
        if (scrollTop == 0) {
            setShowScrollButton(true);
        } else if(scrollTop === scrollHeight - clientHeight){
            setShowScrollButton(false);
        }
    };

    // const scrollToBottoms = () => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // };

    return (
        <div className='chat'>
            <div className='header' style={{ backgroundColor: dark ? '' : 'rgb(24, 24, 27)' }}>
                <div className='logoAI'>
                    <img style={{ width: 50, height: 50, borderRadius: "50%" }} src="https://png.pngtree.com/png-vector/20220707/ourmid/pngtree-chatbot-robot-concept-chat-bot-png-image_5632381.png" />
                    <p style={{ color: dark ? 'black' : 'white' }}>ChatBot AI 360</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                    <span onClick={handleConvertDark}>
                        {dark ? <MoonOutlined title='dark' style={{ fontSize: 20, cursor: 'pointer', color: dark ? 'black' : 'white' }} /> : <SunOutlined title='light' style={{ fontSize: 20, cursor: 'pointer', color: dark ? 'black' : 'white' }} />}
                    </span>
                    <PlusSquareOutlined
                        className='newChat'
                        title='New Chat'
                        onClick={handleNewChat}
                        style={{ color: dark ? 'black' : 'white' }}
                    />
                </div>
            </div>
            <div
                className='content'
                onWheel={handleWheel}
                style={{ backgroundColor: dark ? '' : '#000000' }}
            >
                <div
                    className='message-container'
                    onScroll={handleScroll}
                    onWheel={handleWheel}
                >
                    {/* Tin nhắn từ bot */}
                    <div className='message bot-message'>
                        <img src="https://png.pngtree.com/png-vector/20220707/ourmid/pngtree-chatbot-robot-concept-chat-bot-png-image_5632381.png" alt="Bot" />
                        <div style={{ display: 'grid', gap: 3 }}>
                            <span className='message-text'>
                                👋 Hi! I am Chatbot AI 360, ask me anything about Chatbot AI 360!
                            </span>
                            <span className='message-text'>
                                By the way, you can create a chatbot like me for your website! 😮
                            </span>
                        </div>
                    </div>
                    {/* Hiển thị tin nhắn của người dùng */}
                    {messages.map((msg, index) => (
                        <div key={index} className='message user-message'>
                            <div style={{ display: 'grid', gap: 3 }}>
                                <span className='message-text'>{msg.text}</span>
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                    {showScrollButton && (
                    <Button className='backDrop' shape="circle" icon={<CaretDownOutlined />} />)}
                </div>
                <div
                    className='suggestion-buttons'
                    onWheel={handleWheel}
                >
                    <button onClick={() => handleSuggestionClick("What is Chatbot?")}>
                        What is Chatbot?
                    </button>
                    <button onClick={() => handleSuggestionClick("How do I add data to my chatbot?")}>
                        How do I add data to my chatbot?
                    </button>
                    <button onClick={() => handleSuggestionClick("How to create a chatbot?")}>
                        How to create a chatbot?
                    </button>
                    <button onClick={() => handleSuggestionClick("Chatbot benefits?")}>
                        Chatbot benefits?
                    </button>
                </div>
                <div className='input-container'>
                    <textarea
                        ref={textareaRef}
                        placeholder="Message..."
                        style={{ backgroundColor: dark ? 'white' : 'black', color: dark ? 'black' : 'white' }}
                        rows="1"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                    />
                    <AudioOutlined title='micro' size="24px" style={{ color: dark ? 'black' : 'white', cursor: 'pointer' }} />
                    <button
                        className='send-button'
                        onClick={handleSendMessage}
                        title='send'
                    >
                        <svg viewBox="0 0 24 24" width="24" height="24" style={{ color: dark ? 'black' : 'white' }}>
                            <path fill="currentColor" d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className='footer' style={{ backgroundColor: dark ? '' : 'rgb(24, 24, 27)' }}>
                <p style={{ color: dark ? 'black' : 'white' }}>Powered by Duc IT Group</p>
            </div>
        </div>
    )
}

export default ChatBot;