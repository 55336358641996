import React from 'react'
import { createRoot } from 'react-dom/client'
import './styles.css'

import {
  BrowserRouter
} from "react-router-dom";

import { I18nextProvider } from 'react-i18next';
import i18n from './i18next'
import App from './App';
import RotateDevice from './Component/rotateScreen';
import { AutoRotateProvider } from './Component/pauseState/AutoRotateContext';

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <AutoRotateProvider>
      <I18nextProvider i18n={i18n}>
        <RotateDevice />
        <App />
      </I18nextProvider>
    </AutoRotateProvider>
  </BrowserRouter>
)
