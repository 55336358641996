import React, { useState, useRef, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import { Button, Modal } from 'antd';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18next'

import { GlobalOutlined, SoundOutlined, MutedOutlined, InfoCircleOutlined, PictureOutlined, DownCircleOutlined, UpCircleOutlined, ZoomInOutlined, ZoomOutOutlined, HomeOutlined, CloseOutlined, ExpandOutlined, CompressOutlined, UndoOutlined } from '@ant-design/icons';

import '../Footer/styles.css'

import { AutoRotateContext } from '../pauseState/AutoRotateContext';
import { events } from '@react-three/fiber';
import { getAll, getOne } from '../../services/Homeservices';
import axios from 'axios';
import { getBuildingIdFromPath } from './buildingIds';
import { getApiUrl } from '../../Page/utils/apiConfig';

function GGMap() {
    const [isMapVisible, setIsMapVisible] = useState(true);
    const [size, setSize] = useState('large');

    const handleToggleMap = () => {
        setIsMapVisible(!isMapVisible);
    };

    return (
        <div className='ggMap'>
            {isMapVisible && (
                <div className='container' style={{ zIndex: 99999999 }}>
                    <Button
                        onClick={() => setIsMapVisible(false)}
                        type="primary"
                        icon={<CloseOutlined />}
                        size={size}
                        className="close-button"
                        color='#fa9627'
                    />
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15018.734198011669!2d105.7579011871582!3d19.768595100000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3136f87eb8b4c8c5%3A0x88d1e9c521aef3a9!2zxJBhzKNpIEhvzKNjIEjDtMyAbmcgxJDGsMyBYyAx!5e0!3m2!1sfr!2s!4v1715850514238!5m2!1sfr!2s"
                        className="map-iframe"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            )}
        </div>
    );
}

function ImageGrid({ onClose }) {
    const [images, setImages] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const buildingId = getBuildingIdFromPath(location.pathname);
                if (!buildingId) {
                    console.error('Không tìm thấy ID tòa nhà cho đường dẫn này');
                    return;
                }
                const apiUrl = getApiUrl(`/api/toanha/${buildingId}`);
                const response = await axios.get(apiUrl);

                // Xử lý URL hình ảnh từ dữ liệu API
                const imageUrls = response.data.image.map(img =>
                    getApiUrl(`/file/${img}`)
                );
                setImages(imageUrls); // Lưu hình ảnh vào state
            } catch (error) {
                console.error('Có lỗi xảy ra khi lấy dữ liệu:', error);
            }
        };

        fetchImages();
    }, [location.pathname]);

    return (
        <div className='ImageContainer'>
            <div style={{ height: 40, alignContent: 'center' }}>
                <Button
                    className='closeButton'
                    onClick={onClose}
                    type="primary"
                    borderRadius={1}
                    icon={<CloseOutlined color='black' />}
                />
            </div>
            <div className='gridImage'>
                {images.map((img, index) => (
                    <div className='imgContainer' key={index}>
                        <img className='img' src={img} alt={`image-${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

function BuildingInfoModal() {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [exitAnimation, setExitAnimation] = useState(false);
    const [about, setAbout] = useState('');
    const location = useLocation();
    const containerRef = useRef(null);

    useEffect(() => {
        const fetchBuildingInfo = async () => {
            try {
                const buildingId = getBuildingIdFromPath(location.pathname);
                if (!buildingId) {
                    console.error('Không tìm thấy ID tòa nhà cho đường dẫn này');
                    return;
                }
                const apiUrl = getApiUrl(`/api/toanha/${buildingId}`)
                const response = await axios.get(apiUrl);
                setAbout(response.data.about); 
                // const response = await axios.get(`http://localhost:3000/api/toanha/${buildingId}`);
                // setAbout(response.data.about); 
            } catch (error) {
                console.error('Có lỗi xảy ra khi lấy dữ liệu:', error);
            }
        };

        fetchBuildingInfo();
    }, [location.pathname]);

    useEffect(() => {
        if (!isModalVisible) {
            setExitAnimation(true);
            const timer = setTimeout(() => {
                setExitAnimation(false);
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [isModalVisible]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handleClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClose = () => {
        setExitAnimation(true);
    };

    const { t } = useTranslation();

    return (
        <div
            className={`custom-modal ${isModalVisible ? 'custom-modal-enter' : ''} ${exitAnimation ? 'custom-modal-exit' : ''}`}
            ref={containerRef}
        >
            <Button onClick={handleClose} type="primary" icon={<CloseOutlined style={{ color: '#fa9627', width: 11, height: 11 }} />} size='2' style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer', zIndex: 10001, backgroundColor: '#ffffff', borderRadius: '50%', width: 25, height: 25 }} />
            <h3>{t('nameSchool')}</h3>
            <div className="scrollable-content">
                <p>{about}</p> {/* Hiển thị dữ liệu about */}
            </div>
        </div>
    );
}

function Footer({ handleZoomIn, handleZoomOut }) {
    // show, hide Map
    const [showMap, setShowMap] = useState(false);
    const handleShowMap = () => {
        setShowMap(!showMap);
    };

    // show , hide Footer
    const [isFooterVisible, setIsFooterVisible] = useState(true);
    const toggleFooterVisibility = () => {
        setIsFooterVisible(!isFooterVisible);
    };

    // Play , Pause AUDIO
    const [isPlaying, setIsPlaying] = useState(false);

    const handleToggleAudio = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const [sound, setSound] = useState('');
    const audioRef = useRef(null);

    useEffect(() => {
        const fetchBuildingInfo = async () => {
            try {
                const buildingId = getBuildingIdFromPath(location.pathname);
                if (!buildingId) {
                    console.error('Không tìm thấy ID tòa nhà cho đường dẫn này');
                    return;
                }

                const response = await axios.get(`http://localhost:3000/api/toanha/${buildingId}`);
                setSound(response.data.sound); // Lưu dữ liệu sound vào state
            } catch (error) {
                console.error('Có lỗi xảy ra khi lấy dữ liệu:', error);
            }
        };

        fetchBuildingInfo();
    }, [location.pathname]);

    useEffect(() => {
        if (sound && audioRef.current) {
            audioRef.current.src = `http://localhost:3000/file/${sound}`;
            audioRef.current.play();
        }
    }, [sound]);

    // show, hide Image
    const [showImageGrid, setShowImageGrid] = useState(false);

    const handleToggleImageGrid = () => {
        setShowImageGrid(!showImageGrid);
    };

    // show, hide About
    const [showAbout, setShowAbout] = useState(false);
    const handleShowAbout = () => {
        setShowAbout(!showAbout)
    };


    // FullScreen 
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) { // Firefox
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
                document.documentElement.msRequestFullscreen();
            }
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
            setIsFullScreen(false);
        }
    };

    return (
        <>
            <audio ref={audioRef} />
            {isFooterVisible && (
                <div style={{
                    width: '100%',
                    height: '35px',
                    backgroundColor: 'rgba(243, 113, 37, 0.9)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 999999,
                    opacity: 0.8
                }}>
                    <div>
                        <DownCircleOutlined onClick={toggleFooterVisibility} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Ẩn thanh công cụ' />
                    </div>
                    <div style={{ display: 'flex', }}>
                        <GlobalOutlined onClick={handleShowMap} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Vị trí' />
                        {isPlaying ?
                            <SoundOutlined onClick={handleToggleAudio} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Tắt âm' />
                            : <MutedOutlined onClick={handleToggleAudio} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Tắt âm' />
                        }
                        <a href='/'><HomeOutlined style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Toàn cảnh' /></a>
                        <InfoCircleOutlined onClick={handleShowAbout} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Thông tin' />
                        <PictureOutlined onClick={handleToggleImageGrid} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Tất cả các ảnh' />
                    </div>
                    <div>
                    </div>
                </div>
            )}
            {!isFooterVisible && (
                <UpCircleOutlined title='Hiện thanh công cụ' onClick={toggleFooterVisibility} style={{ color: '#fa9627', fontSize: 30, position: 'fixed', bottom: 10, right: 30, cursor: 'pointer', zIndex: 1000 }} />
            )}
            {showMap && <GGMap />}
            {showImageGrid && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9998
                }}>
                    <ImageGrid onClose={handleToggleImageGrid} />
                </div>
            )}
            {showAbout && (
                <BuildingInfoModal />
            )}

        </>
    );
}

export default Footer;

