import React from "react";
import axios from "axios"

export const URL ={
    HOMEPAGE: "/",
    CONTACT: "/contact",
    PAGE: {
        A1: "/a1",
        A3: "/a3",
        A6: "/a6",
        A2: "/a2",
        LIBRARY:"/library",
        A5: "/a5",
        PHYSICAL: "/giao-duc-the-chat",
        A7: "/a7"
    }
}