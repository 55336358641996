import React, { useState, useEffect } from 'react';

import '../rotateScreen/styleScreen.css'

const RotateDevice = () => {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth *5/4);

  const updateOrientation = () => {
    setIsPortrait(window.innerHeight > window.innerWidth *5/4);
  };

  useEffect(() => {
    window.addEventListener('resize', updateOrientation);
    return () => {
      window.removeEventListener('resize', updateOrientation);
    };
  }, []);

  if (!isPortrait) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.message}>
        <img width={50} height={50} className='imageScreen' src='XoayDT.png'/>
        &nbsp;
        &nbsp;
        <p style={{color:'#fff'}}>Vui lòng xoay ngang thiết bị của bạn để trải nghiệm tốt hơn.</p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999999000,
  },
  message: {
    color: '#fff',
    fontSize: '20px',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#000',
    borderRadius: '10px',
  },
};

export default RotateDevice;
